<template>
  <div id="app" class="">
    <div class="blurBackdrop"></div>
    <tour />

    <!-- <loading :active.sync="$store.state.loader" :is-full-page="true"></loading> -->
    <div id="nav">
      <div v-if="$route.query.error_description">
        <img src="./assets/images/alert.png" width="50" height="50" />
        <p class="pt-3">{{ $route.query.error_description }}</p>
        <a @click="gotoLogin()" style="cursor: pointer">
          Click here to goto login screen
        </a>
      </div>
    </div>
    <podSessionModal />
    <demo-modal />
    <ChooseYourExperienceModal />
    <PodOperatorModals />
    <errorModal />
    <ConfirmationSteps />
    <router-view />
    <CourseSettingsModal />
    <CreateTemplateModal />
    <ErrorSigningDoc />
    <SessionsAdded />
    <cancelModal />
  </div>
</template>
<script>
import demoModal from "@/views/demoModal.vue";
import navigation from "@/components/navigation";
import podSessionModal from "@/components/podSessionModal";
import ChooseYourExperienceModal from "./components/ChooseYourExperienceModal";
import PodOperatorModals from "./components/PodOperatorModals";
import errorModal from "@/components/errorModal.vue";
import ConfirmationSteps from "./components/confirmationSteps.vue";
import CourseSettingsModal from "./components/instructor/CourseSettingsModal.vue";
import Loading from "vue-loading-overlay";
import tour from "@/components/HowToUsePortal.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import CreateTemplateModal from "@/components/instructor/CreateTemplateModal.vue";
import ErrorSigningDoc from "@/components/ErrorSigningDoc";
import SessionsAdded from "@/components/instructor/SessionsAdded.vue";
import EditTemplateModalTwo from "@/components/instructor/EditTemplateModalTwo.vue";
import cancelModal from "@/components/cancelModals.vue";
import $ from "jquery";
import { Hub } from "aws-amplify";
import { mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    demoModal,
    navigation,
    podSessionModal,
    ChooseYourExperienceModal,
    PodOperatorModals,
    ConfirmationSteps,
    CourseSettingsModal,
    Loading,
    tour,
    CreateTemplateModal,
    ErrorSigningDoc,
    SessionsAdded,
    EditTemplateModalTwo,
    errorModal,
    cancelModal,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["loadUser", "clearUserState"]),
    gotoLogin() {
      this.$router.push({ path: "/", query: { signin: "true" } });
    },
    onStudentClick() {
      this.$store.state.role = "student";
      this.$store.state.component = "landing";
      this.$store.state.view = "alien";
    },
    onGuestClick() {
      this.$store.state.role = "guest";
      this.$store.state.component = "cya";
      this.$store.state.view = "alien";
    },
    onPodOperatorClick() {
      this.$store.state.role = "pod operator";
      this.$store.state.component = "";
      this.$store.state.view = "";
    },
    onInstructorClick() {
      this.$store.state.role = "instructor";
      this.$store.state.component = "";
      this.$store.state.view = "";
    },
  },
  beforeCreate() {
    // Listen to Auth events
    Hub.listen("auth", ({ payload: { message, event, data } }) => {
      // set auth state

      switch (event) {
        case "signIn":
          this.loadUser();
          break;
        case "signOut":
          this.clearUserState();
          break;
        case "signIn_failure":
          if (message == "The OAuth response flow failed") {
            console.log(data.message);
          }
          break;
        case "cognitoHostedUI": // https://github.com/aws-amplify/amplify-js/issues/7081
          localStorage.setItem("amplify-redirected-from-hosted-ui", false);
          break;
      }
    });
    // Set DSL API availability flag
    this.$store.commit(
      "setDSLApiFlag",
      process.env.VUE_APP_ASULINK_API_URL && process.env.VUE_APP_ASULINK_API_KEY
        ? true
        : false
    );
    // Set state if auth session exists
    this.$store.dispatch("loadUser");
  },
  mounted() {
    $(document)
      .on("show.bs.modal", ".modal", function () {
        $(document.body).addClass("modal-open");
      })
      .on("hidden.bs.modal", ".modal", function () {
        $(document.body).removeClass("modal-open");
      });
    if (window.location.href.includes("dashboard")) {
      this.$router.push({ path: "/portal" });
    }
    let vm = this;
    setInterval(() => {
      if (vm.$store.state.role == "pod operator") {
        // reload page on particular time
        var now = new Date();
        if (now.getHours() > 7 || now.getHours() < 19) {
          if (vm.$store.state.pod_checkin) {
            $("#pod-check-in").modal();
            vm.$store.commit("POD_CHECKIN", true);
          }
          if (now.getHours() == 7) {
            location.reload();
          }
        } else if (vm.$store.state.pod_checkin && now.getHours() == 20) {
          // close check-in modal
          $("#pod-check-in").modal("hide");
          vm.$store.commit("POD_CHECKIN", false);
        }
      }
    }, 3600000);
  },
};
</script>

<style lang="scss">
.modal {
  input,
  select {
    height: 40px !important;
  }
}
.br-18 {
  border-radius: 18px !important;
}
.b-gold {
  border: 1px solid #93751f !important;
}
#app {
  text-align: center;
  background: #151515;
  color: white;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #272727;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #000;
}
a {
  color: #ffc627 !important;
}
button:focus {
  outline: 0 !important;
}

body {
  background: #151515 !important;
  height: 100vh;
}
.pb-12p {
  padding-bottom: 12px !important;
}

.pt-14p {
  padding-top: 14px !important;
}
.pb-10p {
  padding-bottom: 10px !important;
}
@media only screen and (max-width: 767px) {
  #editTemplate,
  #editTemplate-2,
  #createATemplate,
  #batchCancelTemplate,
  #createSession,
  #import-shows,
  #invite-participants,
  #batchApplyTemplate,
  #checkin-modal {
    .modal-dialog-centered {
      align-items: end;
    }
    .modal-dialog {
      bottom: 0;
      position: inherit;
      margin-bottom: 0;
      width: 100%;
    }
  }
  .br-18 {
    border-radius: 18px 18px 0px 0px !important;
  }
}
</style>
